#overlay {
  position: fixed;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: var(--primaryColor1); */
  /* Black background with opacity */
  z-index: 9999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  /* background-image: url(../img/bg.jpg); */
  /* background-color: #add8e6; */
  background-image: linear-gradient( 95.2deg, rgb(173, 239, 252) 26.8%, rgba(192,229,246,1) 64% ) !important;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.lds-ripple {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  top: 40%;
  left: 48%;
}

/* -------------------------Loader css here------------- */
.loader-logo {
  margin: 20px;
  width: 100px;
  height: auto;
  background: transparent;

  /* -webkit-animation-name: spin;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 1000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 1000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; */
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .lds-ripple {
    left: 38%;
  }
}